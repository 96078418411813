.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
}
